import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Table, Row, Label, Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import Flatpickr from "react-flatpickr";

import { api } from "../utils/index";
import CardLoader from "./CardLoader";
import MyDateRenderer from "./MyDateRenderer";

const FormReviewSFDA = (props) => {
    const [reviewFormCollapsed, setReviewFormCollapsed] = React.useState(true);
    
    return(
        <Card className="card-secondary mb-3">
            <CardHeader className="border-0 d-flex align-items-center">
                <CardLoader loading={props.loading} />
                <h5 className="card-title mb-0 flex-grow-1 cursor-pointer" onClick={() => setReviewFormCollapsed(!reviewFormCollapsed)}>
                    {reviewFormCollapsed && <i className="ri-add-box-line align-bottom text-primary"></i>}
                    {!reviewFormCollapsed && <i className="ri-checkbox-indeterminate-line align-bottom text-primary"></i>}
                    Application Review Form ({reviewFormCollapsed ? "Expand" : "Collapse"})
                </h5>
            </CardHeader>
            <CardBody className="border border-dashed border-end-0 border-start-0">
                <Collapse isOpen={!reviewFormCollapsed} className="collapse">
                    <Row className="gy-2" style={{backgroundColor:"white"}}>
                        <Col xxl={12} md={12}>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm1 ? "" : "table-form-readonly"}`}>
                                <tbody>
                                    <tr>
                                        <td style={{width:"30%"}}>Product Category</td>
                                        <td colSpan={2}>
                                            <input type="radio" checked={props.fboState && props.fboState.ticket.productCategory === "Cosmetic"} readOnly={true} /> Cosmetic
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"30%"}}>Certification Type</td>
                                        <td colSpan={2}>
                                            <input type="radio" checked={props.fboState && props.fboState.ticket.certificationType === "SCOC"} readOnly={true} /> Shipment Certificate of Conformity (S-CoC)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Route Type</td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "RouteType") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "RouteType", "Route A")} onChange={e => props.setFieldValue("reviewForm", "RouteType", "Route A", e.target.checked)} /> Route A
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "RouteType") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "RouteType", "Route B")} onChange={e => props.setFieldValue("reviewForm", "RouteType", "Route B", e.target.checked)} /> Route B
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm1 ? "" : "table-form-readonly"}`}>
                                <tbody>
                                    <tr>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"55%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"10%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"20%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                    </tr>
                                    <tr className="header">
                                        <td className="index-col">#</td>
                                        <td>Points to be checked<sup>2</sup></td>
                                        <td colSpan={2}>Sales Team</td>
                                        <td>P-CoC</td>
                                        <td>S-CoC</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>1.1</b></td>
                                        <td>Product Registered in SFDA and the products under scope of Establishment. (<a target="_blank" rel="noreferrer" href="https://www.sfda.gov.sa/en/list_countries">link</a>)</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "ScopeEstablishment") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "ScopeEstablishment", "Yes")} onChange={e => props.setFieldValue("reviewForm", "ScopeEstablishment", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "ScopeEstablishment") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "ScopeEstablishment", "No")} onChange={e => props.setFieldValue("reviewForm", "ScopeEstablishment", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">X</td>
                                        <td className="check-col">X</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>1.2</b></td>
                                        <td>Product is under TÜV AUSTRIA’s SFDA Scope</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "ScopeTUV") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "ScopeTUV", "Yes")} onChange={e => props.setFieldValue("reviewForm", "ScopeTUV", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "ScopeTUV") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "ScopeTUV", "No")} onChange={e => props.setFieldValue("reviewForm", "ScopeTUV", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">X</td>
                                        <td className="check-col">X</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>1.3</b></td>
                                        <td>Is the scope of certification sought defined?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "ScopeSought") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "ScopeSought", "Yes")} onChange={e => props.setFieldValue("reviewForm", "ScopeSought", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "ScopeSought") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "ScopeSought", "No")} onChange={e => props.setFieldValue("reviewForm", "ScopeSought", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">X</td>
                                        <td className="check-col">X</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm1 ? "" : "table-form-readonly"}`}>
                                <tbody>
                                    <tr>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"55%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"10%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"10%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"10%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                    </tr>
                                    <tr className="header">
                                        <td className="index-col">#</td>
                                        <td>Record Check<sup>3 4</sup></td>
                                        <td colSpan={3}>Sales Team</td>
                                        <td>P-CoC</td>
                                        <td>S-CoC</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>2.0</b></td>
                                        <td colSpan={4}>
                                            Please click the below link to verify the records.<br />
                                            <a target="_blank" rel="noreferrer" href="https://docs.google.com/spreadsheets/d/1T_jt3nlTFyWK5LJoOOx8VecqM6KuqNGY/edit?usp=sharing&ouid=111697309362798812648&rtpof=true&sd=true">https://docs.google.com/spreadsheets/d/1T_jt3nlTFyWK5LJoOOx8VecqM6KuqNGY/edit?usp=sharing&ouid=111697309362798812648&rtpof=true&sd=true</a>
                                        </td>
                                        <td className="check-col">X</td>
                                        <td className="check-col">X</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>2.1</b></td>
                                        <td>Test report(s)</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "TestReportDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "TestReportDocument", "Yes")} onChange={e => props.setFieldValue("reviewForm", "TestReportDocument", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "TestReportDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "TestReportDocument", "No")} onChange={e => props.setFieldValue("reviewForm", "TestReportDocument", "No", e.target.checked)} /> No
                                        </td>
                                        <td>&nbsp;</td>
                                        <td className="check-col">X</td>
                                        <td className="check-col">X</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">&nbsp;</td>
                                        <td>Is it listed the approved list? (<a target="_blank" href="https://docs.google.com/spreadsheets/d/17bxNp_v_4iaBr-ZDywypPsayV1yM08mH/edit?usp=sharing&ouid=111697309362798812648&rtpof=true&sd=true">link</a>)</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "TestReportDocumentList") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "TestReportDocumentList", "Yes")} onChange={e => props.setFieldValue("reviewForm", "TestReportDocumentList", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "TestReportDocumentList") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "TestReportDocumentList", "No")} onChange={e => props.setFieldValue("reviewForm", "TestReportDocumentList", "No", e.target.checked)} /> No
                                        </td>
                                        <td>&nbsp;</td>
                                        <td className="check-col">X</td>
                                        <td className="check-col">X</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">&nbsp;</td>
                                        <td>Checking of Test Report</td>
                                        <td colSpan={3}>
                                            <div>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-2 pt-1">
                                                        <input type="checkbox" className={`${props.isValidFieldValue("reviewForm", "VerifyAccreditationBody", "Yes") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "VerifyAccreditationBody", "Yes")} onChange={e => props.setFieldValue("reviewForm", "VerifyAccreditationBody", "Yes", e.target.checked)} /> Accreditation Body:
                                                    </div>
                                                    <div className="flex-grow-1 w-100">
                                                        <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("reviewForm", "VerifyAccreditationBodyText") ? "" : "is-invalid"}`} readOnly={!(props.fboState && props.fboState.canEditForm1)}
                                                            value={props.getFieldValueText("reviewForm", "VerifyAccreditationBodyText") || ""}
                                                            onChange={e => props.setFieldValueText("reviewForm", "VerifyAccreditationBodyText", e.target.value)}
                                                        />
                                                    </div>
                                                </div>                                            
                                            </div>
                                            <div>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-2 pt-1">
                                                        <input type="checkbox" className={`${props.isValidFieldValue("reviewForm", "VerifyTestingLaboratory", "Yes") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "VerifyTestingLaboratory", "Yes")} onChange={e => props.setFieldValue("reviewForm", "VerifyTestingLaboratory", "Yes", e.target.checked)} /> Testing Laboratory:
                                                    </div>
                                                    <div className="flex-grow-1 w-100">
                                                        <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("reviewForm", "VerifyTestingLaboratoryText") ? "" : "is-invalid"}`} readOnly={!(props.fboState && props.fboState.canEditForm1)}
                                                            value={props.getFieldValueText("reviewForm", "VerifyTestingLaboratoryText") || ""}
                                                            onChange={e => props.setFieldValueText("reviewForm", "VerifyTestingLaboratoryText", e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-2 pt-1">
                                                        <input type="checkbox" className={`${props.isValidFieldValue("reviewForm", "VerifyReportDate", "Yes") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "VerifyReportDate", "Yes")} onChange={e => props.setFieldValue("reviewForm", "VerifyReportDate", "Yes", e.target.checked)} /> Test Report Date:&nbsp;&nbsp;&nbsp;
                                                    </div>
                                                    <div className="flex-grow-1 w-100">
                                                        {props.fboState && props.fboState.canEditForm1 &&
                                                            <Flatpickr className={`form-control form-control-sm w-100 ${props.isValidFieldValue("reviewForm", "VerifyReportDateText") ? "" : "is-invalid"}`}
                                                                value={props.getFieldValueText("reviewForm", "VerifyReportDateText") || ""}
                                                                onChange={([date]) => props.setFieldValueText("reviewForm", "VerifyReportDateText", date)}
                                                                options={{
                                                                    enableTime: false,
                                                                    dateFormat: "m/d/Y",
                                                                    disable: [
                                                                        function(date) {
                                                                            return (date >= new Date());
                                                                        }
                                                                    ]
                                                                }}
                                                            />
                                                        }
                                                        {props.fboState && !props.fboState.canEditForm1 &&
                                                            <input type="text" className="form-control form-control-sm w-100" readOnly={true}
                                                                value={props.getFieldValueDate("reviewForm", "VerifyReportDateText") || ""}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>2.2</b></td>
                                        <td>HALAL Certificate (food - cosmetic)</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "HalalCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "HalalCertificate", "Yes")} onChange={e => props.setFieldValue("reviewForm", "HalalCertificate", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "HalalCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "HalalCertificate", "No")} onChange={e => props.setFieldValue("reviewForm", "HalalCertificate", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "HalalCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "HalalCertificate", "N/A")} onChange={e => props.setFieldValue("reviewForm", "HalalCertificate", "N/A", e.target.checked)} /> N/A
                                        </td>
                                        <td className="check-col">X</td>
                                        <td className="check-col">X</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>2.3</b></td>
                                        <td>Organic Certificate (It must be provided if it is mentioned that Organic product in the product label.)</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "OrganicCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "OrganicCertificate", "Yes")} onChange={e => props.setFieldValue("reviewForm", "OrganicCertificate", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "OrganicCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "OrganicCertificate", "No")} onChange={e => props.setFieldValue("reviewForm", "OrganicCertificate", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "OrganicCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "OrganicCertificate", "N/A")} onChange={e => props.setFieldValue("reviewForm", "OrganicCertificate", "N/A", e.target.checked)} /> N/A
                                        </td>
                                        <td className="check-col">X</td>
                                        <td className="check-col">X</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>2.4</b></td>
                                        <td>MSDS</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "MSDS") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "MSDS", "Yes")} onChange={e => props.setFieldValue("reviewForm", "MSDS", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "MSDS") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "MSDS", "No")} onChange={e => props.setFieldValue("reviewForm", "MSDS", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "MSDS") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "MSDS", "N/A")} onChange={e => props.setFieldValue("reviewForm", "MSDS", "N/A", e.target.checked)} /> N/A
                                        </td>
                                        <td className="check-col">X</td>
                                        <td className="check-col">X</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>2.5</b></td>
                                        <td>Ingredients Formulation</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "IngredientFormulation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "IngredientFormulation", "Yes")} onChange={e => props.setFieldValue("reviewForm", "IngredientFormulation", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "IngredientFormulation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "IngredientFormulation", "No")} onChange={e => props.setFieldValue("reviewForm", "IngredientFormulation", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "IngredientFormulation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "IngredientFormulation", "N/A")} onChange={e => props.setFieldValue("reviewForm", "IngredientFormulation", "N/A", e.target.checked)} /> N/A
                                        </td>
                                        <td className="check-col">X</td>
                                        <td className="check-col">X</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>2.6</b></td>
                                        <td>Visible and clear PHOTOS</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "Photos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "Photos", "Yes")} onChange={e => props.setFieldValue("reviewForm", "Photos", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "Photos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "Photos", "No")} onChange={e => props.setFieldValue("reviewForm", "Photos", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "Photos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "Photos", "N/A")} onChange={e => props.setFieldValue("reviewForm", "Photos", "N/A", e.target.checked)} /> N/A
                                        </td>
                                        <td className="check-col">X</td>
                                        <td className="check-col">X</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>2.7</b></td>
                                        <td>Other Qualification documents</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "OtherQualificationDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "OtherQualificationDocument", "Yes")} onChange={e => props.setFieldValue("reviewForm", "OtherQualificationDocument", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "OtherQualificationDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "OtherQualificationDocument", "No")} onChange={e => props.setFieldValue("reviewForm", "OtherQualificationDocument", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "OtherQualificationDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "OtherQualificationDocument", "N/A")} onChange={e => props.setFieldValue("reviewForm", "OtherQualificationDocument", "N/A", e.target.checked)} /> N/A
                                        </td>
                                        <td className="check-col">X</td>
                                        <td className="check-col">X</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>2.8</b></td>
                                        <td className="p-0">
                                            <table className="table-form-inner">
                                                <tbody>
                                                    <tr>
                                                        <td>Commercial Invoice</td>
                                                        <td className="border-left">
                                                            Invoice Number:
                                                        </td>
                                                        <td style={{width:"20%"}}>
                                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("reviewForm", "CommercialInvoiceNumber") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm1)}
                                                                value={props.getFieldValueText("reviewForm", "CommercialInvoiceNumber") || ""}
                                                                onChange={e => props.setFieldValueText("reviewForm", "CommercialInvoiceNumber", e.target.value)}
                                                            /> 
                                                        </td>
                                                        <td className="border-left">
                                                            Invoice Date
                                                        </td>
                                                        <td style={{width:"20%"}}>
                                                            {props.fboState && props.fboState.canEditForm1 &&
                                                                <Flatpickr className={`form-control form-control-sm w-100 ${props.isValidFieldValue("reviewForm", "CommercialInvoiceDate") ? "" : ""}`}
                                                                    value={props.getFieldValueText("reviewForm", "CommercialInvoiceDate") || ""}
                                                                    onChange={([date]) => props.setFieldValueText("reviewForm", "CommercialInvoiceDate", date)}
                                                                    options={{
                                                                        enableTime: false,
                                                                        dateFormat: "m/d/Y"
                                                                    }}
                                                                />
                                                            }
                                                            {props.fboState && !props.fboState.canEditForm1 &&
                                                                <input type="text" className="form-control form-control-sm w-100" readOnly={true}
                                                                    value={props.getFieldValueDate("reviewForm", "CommercialInvoiceDate") || ""}
                                                                />
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "CommercialInvoice") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "CommercialInvoice", "Yes")} onChange={e => props.setFieldValue("reviewForm", "CommercialInvoice", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "CommercialInvoice") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "CommercialInvoice", "No")} onChange={e => props.setFieldValue("reviewForm", "CommercialInvoice", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "CommercialInvoice") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "CommercialInvoice", "N/A")} onChange={e => props.setFieldValue("reviewForm", "CommercialInvoice", "N/A", e.target.checked)} /> N/A
                                        </td>
                                        <td className="check-col">-</td>
                                        <td className="check-col">X</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>2.9</b></td>
                                        <td className="p-0">
                                            <table className="table-form-inner">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Number of Item in Invoice:
                                                        </td>
                                                        <td style={{width:"20%"}}>
                                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("reviewForm", "CommercialInvoiceItemCount") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm1)}
                                                                value={props.getFieldValueText("reviewForm", "CommercialInvoiceItemCount") || ""}
                                                                onChange={e => props.setFieldValueNumber("reviewForm", "CommercialInvoiceItemCount", e.target.value)}
                                                            /> 
                                                        </td>
                                                        <td className="border-left">
                                                            Number of Test Provided by the client:
                                                        </td>
                                                        <td style={{width:"20%"}}>
                                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("reviewForm", "CommercialInvoiceTestCount") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm1)}
                                                                value={props.getFieldValueText("reviewForm", "CommercialInvoiceTestCount") || ""}
                                                                onChange={e => props.setFieldValueNumber("reviewForm", "CommercialInvoiceTestCount", e.target.value)}
                                                            /> 
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "CommercialInvoiceCount") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "CommercialInvoiceCount", "Yes")} onChange={e => props.setFieldValue("reviewForm", "CommercialInvoiceCount", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "CommercialInvoiceCount") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "CommercialInvoiceCount", "No")} onChange={e => props.setFieldValue("reviewForm", "CommercialInvoiceCount", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "CommercialInvoiceCount") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "CommercialInvoiceCount", "N/A")} onChange={e => props.setFieldValue("reviewForm", "CommercialInvoiceCount", "N/A", e.target.checked)} /> N/A
                                        </td>
                                        <td className="check-col">-</td>
                                        <td className="check-col">X</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm1 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={5}>Result of Application Review</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{width:"50%"}}>The Certification Application is acceptable?</td>
                                        <td style={{width:"25%"}}>
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "CertificationAcceptable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "CertificationAcceptable", "Yes")} onChange={e => props.setFieldValue("reviewForm", "CertificationAcceptable", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td style={{width:"25%"}}>
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "CertificationAcceptable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "CertificationAcceptable", "No")} onChange={e => props.setFieldValue("reviewForm", "CertificationAcceptable", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Name</td>
                                        <td>Signature</td>
                                        <td>Date</td>
                                    </tr>
                                    <tr>
                                        <td>{(props.fboState && props.fboState.reviewForm && props.fboState.reviewForm.lastUpdateByName) || ""}&nbsp;</td>
                                        <td>
                                            {props.fboState && props.fboState.reviewForm && props.fboState.reviewForm.lastUpdateBySignature &&
                                                <div>
                                                    <img className="img-fluid" style={{maxWidth:"100%", maxHeight: "100px", cursor: "pointer"}} src={`${api}Document/DownloadDocumentByName?fileName=${props.fboState.reviewForm.lastUpdateBySignature}`} alt="Not Found" />
                                                </div>
                                            }&nbsp;
                                        </td>
                                        <td>
                                            <MyDateRenderer value={(props.fboState && props.fboState.reviewForm && props.fboState.reviewForm.ticketForm && props.fboState.reviewForm.ticketForm.dateLastEdit) || ""} format={"dd.mm.yyyy HH:MM:ss"} />&nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table-form table-form-alt">
                                <tbody>
                                    <tr>
                                        <td style={{width:"30%", paddingTop:10}}>------------------------------------------</td>
                                        <td style={{width:"30%"}}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>1</sup> Application review may be carried out by different appointed persons or simultaneously by the same person.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>2</sup> If there is any missing information in <b>“Points to be Checked”</b> section, please connect with the client.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>3</sup> If there is any missing file in <b>“Records Check”</b> section, please connect with the client. This section will specifically look for the existence of a document. Detailed examination will be done in the evaluation section.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>4</sup> Please forward the file with the relevant attachments to the operations department for evaluation.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Collapse>
            </CardBody>
        </Card>
    );
};

export default FormReviewSFDA;